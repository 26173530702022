export const countries = [
  {
    "label": "United States",
    "value": "USA",
    "code": "+1"
  },
  {
    "label": "Afghanistan",
    "value": "AFG",
    "code": "+93"
  },
  {
    "label": "Albania",
    "value": "ALB",
    "code": "+355"
  },
  {
    "label": "Algeria",
    "value": "DZA",
    "code": "+213"
  },
  {
    "label": "American Samoa",
    "value": "ASM",
    "code": "+1-684"
  },
  {
    "label": "Andorra",
    "value": "AND",
    "code": "+376"
  },
  {
    "label": "Angola",
    "value": "AGO",
    "code": "+244"
  },
  {
    "label": "Anguilla",
    "value": "AIA",
    "code": "+1"
  },
  {
    "label": "Antarctica",
    "value": "ATA",
    "code": "+672"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "ATG",
    "code": "+1-268"
  },
  {
    "label": "Argentina",
    "value": "ARG",
    "code": "+54"
  },
  {
    "label": "Armenia",
    "value": "ARM",
    "code": "+374"
  },
  {
    "label": "Aruba",
    "value": "ABW",
    "code": "+297"
  },
  {
    "label": "Australia",
    "value": "AUS",
    "code": "+61"
  },
  {
    "label": "Austria",
    "value": "AUT",
    "code": "+43"
  },
  {
    "label": "Azerbaijan",
    "value": "AZE",
    "code": "+994"
  },
  {
    "label": "Bahamas",
    "value": "BHS",
    "code": "+1"
  },
  {
    "label": "Bahrain",
    "value": "BHR",
    "code": "+973"
  },
  {
    "label": "Bangladesh",
    "value": "BGD",
    "code": "+880"
  },
  {
    "label": "Barbados",
    "value": "BRB",
    "code": "+1"
  },
  {
    "label": "Belarus",
    "value": "BLR",
    "code": "+375"
  },
  {
    "label": "Belgium",
    "value": "BEL",
    "code": "+32"
  },
  {
    "label": "Belize",
    "value": "BLZ",
    "code": "+501"
  },
  {
    "label": "Benin",
    "value": "BEN",
    "code": "+229"
  },
  {
    "label": "Bermuda",
    "value": "BMU",
    "code": "+1"
  },
  {
    "label": "Bhutan",
    "value": "BTN",
    "code": "+975"
  },
  {
    "label": "Bolivia, Plurinational State of",
    "value": "BOL",
    "code": "+591"
  },
  {
    "label": "Bonaire, Sint Eustatius and Saba",
    "value": "BES",
    "code": "+599"
  }, 
  {
    "label": "Bosnia and Herzegovina",
    "value": "BIH",
    "code": "+387"
  },
  {
    "label": "Botswana",
    "value": "BWA",
    "code": "+267"
  },
  {
    "label": "Bouvet Island",
    "value": "BVT",
    "code": "+47"
  },
  {
    "label": "Brazil",
    "value": "BRA",
    "code": "+55"
  },
  {
    "label": "British Indian Ocean Territory",
    "value": "IOT",
    "code": "+246"
  },
  {
    "label": "Brunei Darussalam",
    "value": "BRN",
    "code": "+673"
  },
  {
    "label": "Bulgaria",
    "value": "BGR",
    "code": "+359"
  },
  {
    "label": "Burkina Faso",
    "value": "BFA",
    "code": "+226"
  },
  {
    "label": "Burundi",
    "value": "BDI",
    "code": "+257"
  },
  {
    "label": "Cambodia",
    "value": "KHM",
    "code": "+855"
  },
  {
    "label": "Cameroon",
    "value": "CMR",
    "code": "+237"
  },
  {
    "label": "Canada",
    "value": "CAN",
    "code": "+1"
  },
  {
    "label": "Cape Verde",
    "value": "CPV",
    "code": "+238"
  },
  {
    "label": "Cayman Islands",
    "value": "CYM",
    "code": "+1"
  },
  {
    "label": "Central African Republic",
    "value": "CAF",
    "code": "+236"
  },
  {
    "label": "Chad",
    "value": "TCD",
    "code": "+235"
  },
  {
    "label": "Chile",
    "value": "CHL",
    "code": "+56"
  },
  {
    "label": "China",
    "value": "CHN",
    "code": "+86"
  },
  {
    "label": "Christmas Island",
    "value": "CXR",
    "code": "+61"
  },
  {
    "label": "Cocos (Keeling) Islands",
    "value": "CCK",
    "code": "+61"
  },
  {
    "label": "Colombia",
    "value": "COL",
    "code": "+57"
  },
  {
    "label": "Comoros",
    "value": "COM",
    "code": "+269"
  },
  {
    "label": "Congo",
    "value": "COG",
    "code": "242"
  },
  {
    "label": "Congo, the Democratic Republic of the",
    "value": "COD",
    "code": "243"
  },
  {
    "label": "Cook Islands",
    "value": "COK",
    "code": "682"
  },
  {
    "label": "Costa Rica",
    "value": "CRI",
    "code": "506"
  },
  {
    "label": "Croatia",
    "value": "HRV",
    "code": "385"
  },
  {
    "label": "Cuba",
    "value": "CUB",
    "code": "53"
  },
  {
    "label": "Curaçao",
    "value": "CUW",
    "code": "599"
  },
  {
    "label": "Cyprus",
    "value": "CYP",
    "code": "357"
  },
  {
    "label": "Czech Republic",
    "value": "CZE",
    "code": "420"
  },
  {
    "label": "Côte d'Ivoire",
    "value": "CIV",
    "code": "225"
  },
  {
    "label": "Denmark",
    "value": "DNK",
    "code": "45"
  },
  {
    "label": "Djibouti",
    "value": "DJI",
    "code": "253"
  },
  {
    "label": "Dominica",
    "value": "DMA",
    "code": "1-767"
  },
  {
    "label": "Dominican Republic",
    "value": "DOM",
    "code": "1-809, 1-829, 1-849"
  },
  {
    "label": "Ecuador",
    "value": "ECU",
    "code": "593"
  },  
  {
    "label": "Egypt",
    "value": "EGY",
    "code": "20"
  },
  {
    "label": "El Salvador",
    "value": "SLV",
    "code": "503"
  },
  {
    "label": "Equatorial Guinea",
    "value": "GNQ",
    "code": "240"
  },
  {
    "label": "Eritrea",
    "value": "ERI",
    "code": "291"
  },
  {
    "label": "Estonia",
    "value": "EST",
    "code": "372"
  },
  {
    "label": "Ethiopia",
    "value": "ETH",
    "code": "251"
  },
  {
    "label": "Falkland Islands (Malvinas)",
    "value": "FLK",
    "code": "500"
  },
  {
    "label": "Faroe Islands",
    "value": "FRO",
    "code": "298"
  },
  {
    "label": "Fiji",
    "value": "FJI",
    "code": "679"
  },
  {
    "label": "Finland",
    "value": "FIN",
    "code": "358"
  },
  {
    "label": "France",
    "value": "FRA",
    "code": "33"
  },
  {
    "label": "French Guiana",
    "value": "GUF",
    "code": "594"
  },
  {
    "label": "French Polynesia",
    "value": "PYF",
    "code": "689"
  },
  {
    "label": "French Southern Territories",
    "value": "ATF",
    "code": "262"
  },
  {
    "label": "Gabon",
    "value": "GAB",
    "code": "241"
  },
  {
    "label": "Gambia",
    "value": "GMB",
    "code": "220"
  },
  {
    "label": "Georgia",
    "value": "GEO",
    "code": "995"
  },
  {
    "label": "Germany",
    "value": "DEU",
    "code": "49"
  },
  {
    "label": "Ghana",
    "value": "GHA",
    "code": "233"
  },
  {
    "label": "Gibraltar",
    "value": "GIB",
    "code": "350"
  },
  {
    "label": "Greece",
    "value": "GRC",
    "code": "30"
  },
  {
    "label": "Greenland",
    "value": "GRL",
    "code": "299"
  },
  {
    "label": "Grenada",
    "value": "GRD",
    "code": "1-473"
  },  
  {
    "label": "Guadeloupe",
    "value": "GLP",
    "code": "590"
  },
  {
    "label": "Guam",
    "value": "GUM",
    "code": "1-671"
  },
  {
    "label": "Guatemala",
    "value": "GTM",
    "code": "502"
  },
  {
    "label": "Guernsey",
    "value": "GGY",
    "code": "44"
  },
  {
    "label": "Guinea",
    "value": "GIN",
    "code": "224"
  },
  {
    "label": "Guinea-Bissau",
    "value": "GNB",
    "code": "245"
  },
  {
    "label": "Guyana",
    "value": "GUY",
    "code": "592"
  },
  {
    "label": "Haiti",
    "value": "HTI",
    "code": "509"
  },
  {
    "label": "Heard Island and McDonald Islands",
    "value": "HMD",
    "code": ""
  },
  {
    "label": "Holy See (Vatican City State)",
    "value": "VAT",
    "code": "379"
  },
  {
    "label": "Honduras",
    "value": "HND",
    "code": "504"
  },
  {
    "label": "Hong Kong",
    "value": "HKG",
    "code": "852"
  },
  {
    "label": "Hungary",
    "value": "HUN",
    "code": "36"
  },
  {
    "label": "Iceland",
    "value": "ISL",
    "code": "354"
  },
  {
    "label": "India",
    "value": "IND",
    "code": "91"
  },
  {
    "label": "Indonesia",
    "value": "IDN",
    "code": "62"
  },
  {
    "label": "Iran, Islamic Republic of",
    "value": "IRN",
    "code": "98"
  },
  {
    "label": "Iraq",
    "value": "IRQ",
    "code": "964"
  },
  {
    "label": "Ireland",
    "value": "IRL",
    "code": "353"
  },
  {
    "label": "Isle of Man",
    "value": "IMN",
    "code": "44"
  },
  {
    "label": "Israel",
    "value": "ISR",
    "code": "972"
  },
  {
    "label": "Italy",
    "value": "ITA",
    "code": "39"
  },
  {
    "label": "Jamaica",
    "value": "JAM",
    "code": "1-876"
  },
  {
    "label": "Japan",
    "value": "JPN",
    "code": "81"
  },
  {
    "label": "Jersey",
    "value": "JEY",
    "code": "44"
  },
  {
    "label": "Jordan",
    "value": "JOR",
    "code": "962"
  },
  {
    "label": "Kazakhstan",
    "value": "KAZ",
    "code": "7"
  },
  {
    "label": "Kenya",
    "value": "KEN",
    "code": "254"
  },
  {
    "label": "Kiribati",
    "value": "KIR",
    "code": "686"
  },
  {
    "label": "Korea, Democratic People's Republic of",
    "value": "PRK",
    "code": "850"
  },
  {
    "label": "Korea, Republic of",
    "value": "KOR",
    "code": "82"
  },
  {
    "label": "Kuwait",
    "value": "KWT",
    "code": "965"
  },  
  {
    "label": "Kyrgyzstan",
    "value": "KGZ",
    "code": "996"
  },
  {
    "label": "Lao People's Democratic Republic",
    "value": "LAO",
    "code": "856"
  },
  {
    "label": "Latvia",
    "value": "LVA",
    "code": "371"
  },
  {
    "label": "Lebanon",
    "value": "LBN",
    "code": "961"
  },
  {
    "label": "Lesotho",
    "value": "LSO",
    "code": "266"
  },
  {
    "label": "Liberia",
    "value": "LBR",
    "code": "231"
  },
  {
    "label": "Libyan Arab Jamahiriya",
    "value": "LBY",
    "code": "218"
  },
  {
    "label": "Liechtenstein",
    "value": "LIE",
    "code": "423"
  },
  {
    "label": "Lithuania",
    "value": "LTU",
    "code": "370"
  },
  {
    "label": "Luxembourg",
    "value": "LUX",
    "code": "352"
  },
  {
    "label": "Macao",
    "value": "MAC",
    "code": "853"
  },
  {
    "label": "Macedonia, the former Yugoslav Republic of",
    "value": "MKD",
    "code": "389"
  },
  {
    "label": "Madagascar",
    "value": "MDG",
    "code": "261"
  },
  {
    "label": "Malawi",
    "value": "MWI",
    "code": "265"
  },
  {
    "label": "Malaysia",
    "value": "MYS",
    "code": "60"
  },
  {
    "label": "Maldives",
    "value": "MDV",
    "code": "960"
  },
  {
    "label": "Mali",
    "value": "MLI",
    "code": "223"
  },
  {
    "label": "Malta",
    "value": "MLT",
    "code": "356"
  },
  {
    "label": "Marshall Islands",
    "value": "MHL",
    "code": "692"
  },
  {
    "label": "Martinique",
    "value": "MTQ",
    "code": "596"
  },
  {
    "label": "Mauritania",
    "value": "MRT",
    "code": "222"
  },
  {
    "label": "Mauritius",
    "value": "MUS",
    "code": "230"
  },
  {
    "label": "Mayotte",
    "value": "MYT",
    "code": "262"
  },
  {
    "label": "Mexico",
    "value": "MEX",
    "code": "52"
  },
  {
    "label": "Micronesia, Federated States of",
    "value": "FSM",
    "code": "691"
  },
  {
    "label": "Moldova, Republic of",
    "value": "MDA",
    "code": "373"
  },
  {
    "label": "Monaco",
    "value": "MCO",
    "code": "377"
  },
  {
    "label": "Mongolia",
    "value": "MNG",
    "code": "976"
  },
  {
    "label": "Montenegro",
    "value": "MNE",
    "code": "382"
  },
  {
    "label": "Montserrat",
    "value": "MSR",
    "code": "1-664"
  },  
  {
    "label": "Morocco",
    "value": "MAR",
    "code": "212"
  },
  {
    "label": "Mozambique",
    "value": "MOZ",
    "code": "258"
  },
  {
    "label": "Myanmar",
    "value": "MMR",
    "code": "95"
  },
  {
    "label": "Namibia",
    "value": "NAM",
    "code": "264"
  },
  {
    "label": "Nauru",
    "value": "NRU",
    "code": "674"
  },
  {
    "label": "Nepal",
    "value": "NPL",
    "code": "977"
  },
  {
    "label": "Netherlands",
    "value": "NLD",
    "code": "31"
  },
  {
    "label": "Netherlands Antilles",
    "value": "ANT",
    "code": "599"
  },
  {
    "label": "New Caledonia",
    "value": "NCL",
    "code": "687"
  },
  {
    "label": "New Zealand",
    "value": "NZL",
    "code": "64"
  },
  {
    "label": "Nicaragua",
    "value": "NIC",
    "code": "505"
  },
  {
    "label": "Niger",
    "value": "NER",
    "code": "227"
  },
  {
    "label": "Nigeria",
    "value": "NGA",
    "code": "234"
  },
  {
    "label": "Niue",
    "value": "NIU",
    "code": "683"
  },
  {
    "label": "Norfolk Island",
    "value": "NFK",
    "code": "672"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "MNP",
    "code": "1-670"
  },
  {
    "label": "Norway",
    "value": "NOR",
    "code": "47"
  },
  {
    "label": "Oman",
    "value": "OMN",
    "code": "968"
  },
  {
    "label": "Pakistan",
    "value": "PAK",
    "code": "92"
  },
  {
    "label": "Palau",
    "value": "PLW",
    "code": "680"
  },
  {
    "label": "Palestinian Territory, Occupied",
    "value": "PSE",
    "code": "970"
  },
  {
    "label": "Panama",
    "value": "PAN",
    "code": "507"
  },
  {
    "label": "Papua New Guinea",
    "value": "PNG",
    "code": "675"
  },
  {
    "label": "Paraguay",
    "value": "PRY",
    "code": "595"
  },
  {
    "label": "Peru",
    "value": "PER",
    "code": "51"
  },
  {
    "label": "Philippines",
    "value": "PHL",
    "code": "63"
  },
  {
    "label": "Pitcairn",
    "value": "PCN",
    "code": "64"
  },
  {
    "label": "Poland",
    "value": "POL",
    "code": "48"
  },
  {
    "label": "Portugal",
    "value": "PRT",
    "code": "351"
  },
  {
    "label": "Puerto Rico",
    "value": "PRI",
    "code": "1-787, 1-939"
  },
  {
    "label": "Qatar",
    "value": "QAT",
    "code": "974"
  },
  {
    "label": "Romania",
    "value": "ROU",
    "code": "40"
  },
  {
    "label": "Russian Federation",
    "value": "RUS",
    "code": "7"
  },
  {
    "label": "Rwanda",
    "value": "RWA",
    "code": "250"
  },
  {
    "label": "Réunion",
    "value": "REU",
    "code": "262"
  },
  {
    "label": "Saint Barthélemy",
    "value": "BLM",
    "code": "590"
  },
  {
    "label": "Saint Helena, Ascension and Tristan da Cunha",
    "value": "SHN",
    "code": "290"
  },  
  {
    "label": "Saint Kitts and Nevis",
    "value": "KNA",
    "code": "1-869"
  },
  {
    "label": "Saint Lucia",
    "value": "LCA",
    "code": "1-758"
  },
  {
    "label": "Saint Martin (French part)",
    "value": "MAF",
    "code": "590"
  },
  {
    "label": "Saint Pierre and Miquelon",
    "value": "SPM",
    "code": "508"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "VCT",
    "code": "1-784"
  },
  {
    "label": "Samoa",
    "value": "WSM",
    "code": "685"
  },
  {
    "label": "San Marino",
    "value": "SMR",
    "code": "378"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "STP",
    "code": "239"
  },
  {
    "label": "Saudi Arabia",
    "value": "SAU",
    "code": "966"
  },
  {
    "label": "Senegal",
    "value": "SEN",
    "code": "221"
  },
  {
    "label": "Serbia",
    "value": "SRB",
    "code": "381"
  },
  {
    "label": "Seychelles",
    "value": "SYC",
    "code": "248"
  },
  {
    "label": "Sierra Leone",
    "value": "SLE",
    "code": "232"
  },
  {
    "label": "Singapore",
    "value": "SGP",
    "code": "65"
  },
  {
    "label": "Sint Maarten (Dutch part)",
    "value": "SXM",
    "code": "1-721"
  },
  {
    "label": "Slovakia",
    "value": "SVK",
    "code": "421"
  },
  {
    "label": "Slovenia",
    "value": "SVN",
    "code": "386"
  },
  {
    "label": "Solomon Islands",
    "value": "SLB",
    "code": "677"
  },
  {
    "label": "Somalia",
    "value": "SOM",
    "code": "252"
  },
  {
    "label": "South Africa",
    "value": "ZAF",
    "code": "27"
  },
  {
    "label": "South Georgia and the South Sandwich Islands",
    "value": "SGS",
    "code": "500"
  },
  {
    "label": "South Sudan",
    "value": "SSD",
    "code": "211"
  },
  {
    "label": "Spain",
    "value": "ESP",
    "code": "34"
  },
  {
    "label": "Sri Lanka",
    "value": "LKA",
    "code": "94"
  },
  {
    "label": "Sudan",
    "value": "SDN",
    "code": "249"
  },
  {
    "label": "Suriname",
    "value": "SUR",
    "code": "597"
  },
  {
    "label": "Svalbard and Jan Mayen",
    "value": "SJM",
    "code": "47"
  },
  {
    "label": "Swaziland",
    "value": "SWZ",
    "code": "268"
  },
  {
    "label": "Sweden",
    "value": "SWE",
    "code": "46"
  },
  {
    "label": "Switzerland",
    "value": "CHE",
    "code": "41"
  },
  {
    "label": "Syrian Arab Republic",
    "value": "SYR",
    "code": "963"
  },
  {
    "label": "Taiwan",
    "value": "TWN",
    "code": "886"
  },
  {
    "label": "Tajikistan",
    "value": "TJK",
    "code": "992"
  },  
  {
    "label": "Tanzania, United Republic of",
    "value": "TZA",
    "code": "255"
  },
  {
    "label": "Thailand",
    "value": "THA",
    "code": "66"
  },
  {
    "label": "Timor-Leste",
    "value": "TLS",
    "code": "670"
  },
  {
    "label": "Togo",
    "value": "TGO",
    "code": "228"
  },
  {
    "label": "Tokelau",
    "value": "TKL",
    "code": "690"
  },
  {
    "label": "Tonga",
    "value": "TON",
    "code": "676"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "TTO",
    "code": "1-868"
  },
  {
    "label": "Tunisia",
    "value": "TUN",
    "code": "216"
  },
  {
    "label": "Turkey",
    "value": "TUR",
    "code": "90"
  },
  {
    "label": "Turkmenistan",
    "value": "TKM",
    "code": "993"
  },
  {
    "label": "Turks and Caicos Islands",
    "value": "TCA",
    "code": "1-649"
  },
  {
    "label": "Tuvalu",
    "value": "TUV",
    "code": "688"
  },
  {
    "label": "Uganda",
    "value": "UGA",
    "code": "256"
  },
  {
    "label": "Ukraine",
    "value": "UKR",
    "code": "380"
  },
  {
    "label": "United Arab Emirates",
    "value": "ARE",
    "code": "971"
  },
  {
    "label": "United Kingdom",
    "value": "GBR",
    "code": "44"
  },
  {
    "label": "United States Minor Outlying Islands",
    "value": "UMI",
    "code": "1"
  },
  {
    "label": "Uruguay",
    "value": "URY",
    "code": "598"
  },
  {
    "label": "Uzbekistan",
    "value": "UZB",
    "code": "998"
  },
  {
    "label": "Vanuatu",
    "value": "VUT",
    "code": "678"
  },
  {
    "label": "Venezuela, Bolivarian Republic of",
    "value": "VEN",
    "code": "58"
  },
  {
    "label": "Viet Nam",
    "value": "VNM",
    "code": "84"
  },
  {
    "label": "Virgin Islands, British",
    "value": "VGB",
    "code": "1-284"
  },
  {
    "label": "Virgin Islands, U.S.",
    "value": "VIR",
    "code": "1-340"
  },
  {
    "label": "Wallis and Futuna",
    "value": "WLF",
    "code": "681"
  },
  {
    "label": "Western Sahara",
    "value": "ESH",
    "code": "212"
  },
  {
    "label": "Yemen",
    "value": "YEM",
    "code": "967"
  },
  {
    "label": "Zambia",
    "value": "ZMB",
    "code": "260"
  },
  {
    "label": "Zimbabwe",
    "value": "ZWE",
    "code": "263"
  },
  {
    "label": "Åland Islands",
    "value": "ALA",
    "code": "358"
  }  
]

export const states = [
  {
      "label": "Alabama",
      "value": "AL"
  },
  {
      "label": "Alaska",
      "value": "AK"
  },
  // {
  //     "label": "American Samoa",
  //     "value": "AS"
  // },
  {
      "label": "Arizona",
      "value": "AZ"
  },
  {
      "label": "Arkansas",
      "value": "AR"
  },
  {
      "label": "California",
      "value": "CA"
  },
  {
      "label": "Colorado",
      "value": "CO"
  },
  {
      "label": "Connecticut",
      "value": "CT"
  },
  {
      "label": "Delaware",
      "value": "DE"
  },
  // {
  //     "label": "District Of Columbia",
  //     "value": "DC"
  // },
  // {
  //     "label": "Federated States Of Micronesia",
  //     "value": "FM"
  // },
  {
      "label": "Florida",
      "value": "FL"
  },
  {
      "label": "Georgia",
      "value": "GA"
  },
  // {
  //     "label": "Guam",
  //     "value": "GU"
  // },
  {
      "label": "Hawaii",
      "value": "HI"
  },
  {
      "label": "Idaho",
      "value": "ID"
  },
  {
      "label": "Illinois",
      "value": "IL"
  },
  {
      "label": "Indiana",
      "value": "IN"
  },
  {
      "label": "Iowa",
      "value": "IA"
  },
  {
      "label": "Kansas",
      "value": "KS"
  },
  {
      "label": "Kentucky",
      "value": "KY"
  },
  {
      "label": "Louisiana",
      "value": "LA"
  },
  {
      "label": "Maine",
      "value": "ME"
  },
  // {
  //     "label": "Marshall Islands",
  //     "value": "MH"
  // },
  {
      "label": "Maryland",
      "value": "MD"
  },
  {
      "label": "Massachusetts",
      "value": "MA"
  },
  {
      "label": "Michigan",
      "value": "MI"
  },
  {
      "label": "Minnesota",
      "value": "MN"
  },
  {
      "label": "Mississippi",
      "value": "MS"
  },
  {
      "label": "Missouri",
      "value": "MO"
  },
  {
      "label": "Montana",
      "value": "MT"
  },
  {
      "label": "Nebraska",
      "value": "NE"
  },
  {
      "label": "Nevada",
      "value": "NV"
  },
  {
      "label": "New Hampshire",
      "value": "NH"
  },
  {
      "label": "New Jersey",
      "value": "NJ"
  },
  {
      "label": "New Mexico",
      "value": "NM"
  },
  {
      "label": "New York",
      "value": "NY"
  },
  {
      "label": "North Carolina",
      "value": "NC"
  },
  {
      "label": "North Dakota",
      "value": "ND"
  },
  // {
  //     "label": "Northern Mariana Islands",
  //     "value": "MP"
  // },
  {
      "label": "Ohio",
      "value": "OH"
  },
  {
      "label": "Oklahoma",
      "value": "OK"
  },
  {
      "label": "Oregon",
      "value": "OR"
  },
  // {
  //     "label": "Palau",
  //     "value": "PW"
  // },
  {
      "label": "Pennsylvania",
      "value": "PA"
  },
  // {
  //     "label": "Puerto Rico",
  //     "value": "PR"
  // },
  {
      "label": "Rhode Island",
      "value": "RI"
  },
  {
      "label": "South Carolina",
      "value": "SC"
  },
  {
      "label": "South Dakota",
      "value": "SD"
  },
  {
      "label": "Tennessee",
      "value": "TN"
  },
  {
      "label": "Texas",
      "value": "TX"
  },
  {
      "label": "Utah",
      "value": "UT"
  },
  {
      "label": "Vermont",
      "value": "VT"
  },
  // {
  //     "label": "Virgin Islands",
  //     "value": "VI"
  // },
  {
      "label": "Virginia",
      "value": "VA"
  },
  {
      "label": "Washington",
      "value": "WA"
  },
  {
      "label": "West Virginia",
      "value": "WV"
  },
  {
      "label": "Wisconsin",
      "value": "WI"
  },
  {
      "label": "Wyoming",
      "value": "WY"
  }
];

export const howHeared = [
  { label: 'School Counselor / Teacher', value: 'School Counselor / Teacher' },
  {
    label: 'Email from the USC Summer Programs / Pre-College office',
    value: 'Email from the USC Summer Programs / Pre-College office'
  },
  { label: 'Social Media', value: 'Social Media' },
  { label: 'Family or Friend', value: 'Family or Friend' },
  { label: 'An online search', value: 'An online search' }
];

export const howHeared_cert = [
  { label: 'Email from the USC',value: 'Email from the USC'},
  { label: 'Social Media', value: 'Social Media' },
  { label: 'Family or Friend', value: 'Family or Friend' },
  { label: 'An online search', value: 'An online search' }
];

export const gender = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' }
];

export const gender_cert = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Prefer not to answer' ,value:'Prefer not to answer'}
];

export const Age = [
  { label: 'I am under 18 years of age', value: 'I am under 18 years of age', checked: true },
  { label: 'I am 18 years of age or older', value: 'I am 18 years of age or older' }
]

export const Course = [
  { label: 'Screenwriting', value: "O96992"},
  { label: 'Directing', value: "Y69156" },
  { label:'Screenwriting & Directing' , value:'O96992&Y69156'}
]

export const regardless = [
  {
    label:
      'American Indian/Alaskan native (including all Original Peoples of the Americas)',
    value:
      'American Indian/Alaskan native (including all Original Peoples of the Americas)'
  },
  {
    label: 'Asian (including Indian subcontinent and Philippines)',
    value: 'Asian (including Indian subcontinent and Philippines)'
  },
  {
    label: 'Black or African-American (including Africa and Caribbean)',
    value: 'Black or African-American (including Africa and Caribbean)'
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander (Original Peoples)',
    value: 'Native Hawaiian or Other Pacific Islander (Original Peoples)'
  },
  {
    label: 'White (including Middle Eastern)',
    value: 'White (including Middle Eastern)'
  }
];

export const Educationlevel = [
  { label: 'High school or equivalent ', value: 'High school or equivalent ' },
  { label: 'Associate degree', value: 'Associate degree'},
  { label: 'Bachelor’s degree', value: 'Bachelor’s degree'},
  { label:'Master’s degree', value:'Master’s degree'},
  { label:'Doctorate or Professional degree', value:'Doctorate or Professional degree'}
]

export const grade = [
  { label: 'Freshman', value: 'Freshman' },
  { label: 'Sophomore', value: 'Sophomore' },
  { label: 'Junior', value: 'Junior' },
  { label: 'Senior', value: 'Senior' }
];

export const workexperience = [
  { label:'0 - 2',value:'0 - 2'},
  { label:'3 - 6', value:'3 - 6'},
  { label:'7 - 10', value:'7 - 10'},
  { label:'10+',value:'10+'}

]

export const residency = [
  {
    label: 'I am a citizen of the United States',
    value: 'I am a citizen of the United States'
  },
  {
    label: 'I am a citizen of the United States, currently living abroad',
    value: 'I am a citizen of the United States, currently living abroad'
  },
  {
    label: 'I am a permanent resident of the United States, with a green card',
    value: 'I am a permanent resident of the United States, with a green card'
  },
  {
    label:
      'I am an international student (non- U.S. citizen, non- U.S. permanent resident)',
    value:
      'I am an international student (non- U.S. citizen, non- U.S. permanent resident)'
  },
  {
    label:
      'I am an international student, currently studying in the U.S. (non- U.S. citizen, non- U.S. permanent resident)',
    value:
      'I am an international student, currently studying in the U.S. (non- U.S. citizen, non- U.S. permanent resident)'
  }
];

export const initialRadioOption = [
  { label: 'Yes', value: 'Yes' },
  {
    label: 'No, not of Hispanic, Latino or Spanish origin',
    value: 'No, not of Hispanic, Latino or Spanish origin'
  }
];

export const addressRadio = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

export const intialStepData = [
  { step: 1, title: 'Applicant Information', completed: false, active: true },
  { step: 2, title: 'Course Information', completed: false, active: false },
  { step: 3, title: 'Payment', completed: false, active: false }
];

const addressFields = [
  {
    type: 'input',
    title: 'Address',
    name: 'parent_address'
  },
  {
    type: 'input',
    title: 'Address 2',
    name: 'parent_address_2'
  },
  {
    type: 'input',
    title: 'City',
    name: 'parent_city'
  },
  {
    type: 'input',
    title: 'State',
    name: 'state'
  },
  {
    type: 'input',
    title: 'Province/County',
    name: 'province_county'
  },
  {
    type: 'CustomSelect',
    title: 'Country',
    name: 'parent_country',
    optionKey: 'countries',
    mode: '',
    placeholder: 'Select a country',
    options: countries
  },
  {
    type: 'input',
    title: 'Zip/Postal Code',
    name: 'parent_zip_postal_code'
  }
];

export const parentInfo = [
  {
    type: 'input',
    title: 'First Name',
    name: 'first_name'
  },
  {
    type: 'input',
    title: 'Last Name',
    name: 'last_name'
  },
  {
    type: 'input',
    title: 'Relationship',
    name: 'relationship'
  },
  {
    type: 'CustomRadio',
    title: 'Address Same as Student',
    name: 'address_same_as_student',
    optionKey: 'addressRadio',
    options: addressRadio
  },
  {
    type: 'input',
    title: 'Home Phone',
    name: 'parent_home_phone'
  },
  {
    type: 'input',
    title: 'Parent Cell Phone',
    name: 'parent_cell_phone'
  },
  {
    type: 'input',
    title: 'Parent Email',
    name: 'parent_email'
  }
];

export const parentInfo2 = [
  {
    type: 'input',
    title: 'First Name',
    name: 'parent_first_name'
  },
  {
    type: 'input',
    title: 'Last Name',
    name: 'parent_last_name'
  },
  {
    type: 'input',
    title: 'Relationship',
    name: 'parent_relationship'
  },
  {
    type: 'CustomRadio',
    title: 'Address Same as Student',
    name: 'parent_address_same_as_student',
    optionKey: 'addressRadio',
    options: addressRadio
  },
  {
    type: 'input',
    title: 'Home Phone',
    name: 'parent_home_phone_2'
  },
  {
    type: 'input',
    title: 'Parent Cell Phone',
    name: 'parent_cell_phone_2'
  },
  {
    type: 'input',
    title: 'Parent Email',
    name: 'parent_email_2'
  }
];

export const expandedParentInfo = [
  {
    type: 'input',
    title: 'First Name',
    name: 'first_name'
  },
  {
    type: 'input',
    title: 'Last Name',
    name: 'last_name'
  },
  {
    type: 'input',
    title: 'Relationship',
    name: 'relationship'
  },
  {
    type: 'CustomRadio',
    title: 'Address Same as Student',
    name: 'address_same_as_student',
    optionKey: 'addressRadio',
    options: addressRadio
  },
  ...addressFields,
  {
    type: 'input',
    title: 'Home Phone',
    name: 'parent_home_phone'
  },
  {
    type: 'input',
    title: 'Parent Cell Phone',
    name: 'parent_cell_phone'
  },
  {
    type: 'input',
    title: 'Parent Email',
    name: 'parent_email'
  }
];

export const expandedParentInfo2 = [
  {
    type: 'input',
    title: 'First Name',
    name: 'parent_first_name'
  },
  {
    type: 'input',
    title: 'Last Name',
    name: 'parent_last_name'
  },
  {
    type: 'input',
    title: 'Relationship',
    name: 'parent_relationship'
  },
  {
    type: 'CustomRadio',
    title: 'Address Same as Student',
    name: 'parent_address_same_as_student',
    optionKey: 'addressRadio',
    options: addressRadio
  },
  ...addressFields,
  {
    type: 'input',
    title: 'Home Phone',
    name: 'parent_home_phone_2'
  },
  {
    type: 'input',
    title: 'Parent Cell Phone',
    name: 'parent_cell_phone_2'
  },
  {
    type: 'input',
    title: 'Parent Email',
    name: 'parent_email_2'
  }
];

export const startDates = ['30/11/2022', '01/12/2022', '02/12/2022'];

export const applicationRules = {
  most_recently_completed: [
    {
      required: true,
      message: 'Please upload a file.'
    }
  ],
  select_timezone: [
    {
      required: true,
      message: 'Timezone is required.'
    }
  ],
  about_yourself: [
    {
      required: true,
      message: 'Please type something about yourself.'
    }
  ],
  url: [
    {
      validator: (_, value) => {
        const re =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        if (value && !re.test(String(value).toLowerCase())) {
          return Promise.reject('Please enter a valid link');
        } else {
          return Promise.resolve();
        }
      }
    }
  ],
  course_id: [
    {
      required: true,
      message: 'Please select any course'
    }
  ],
  start_date: [
    {
      required: true,
      message: 'Please select a course start date'
    }
  ],
  residency: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  identity: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  demographic_info: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  email: [
    {
      required: true,
      message: 'Email is required.'
    },
    {
      type: 'email',
      message: 'Email is invalid.'
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value) {
          return Promise.resolve();
        }
    
        const [username, domain] = value.split('@');
        const isFirstLetterG = domain && domain.charAt(0).toLowerCase() === 'g';
        const isGmailDomain = domain && domain.toLowerCase() === 'gmail.com';
        let pattern = /^[a-zA-Z0-9._%+-]+@(?!g(?!mail\.com$)|y(?!opmail\.com$))[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
        const isFirstLetterSmall = value.charAt(0) === value.charAt(0).toLowerCase();
        
        if(!/(\.\.)+/.test(value)){
          if (isFirstLetterG && !isGmailDomain) {
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
            if (pattern.test(value)) {
              throw new Error('Invalid email format.');
            }
          }else if(!isFirstLetterSmall && pattern.test(value)){
            throw new Error('Invalid email format.');
          }
        }
       
        return Promise.resolve();
      },
    }),
  ],
  address: [
    {
      required: true,
      message: 'Address is required.'
    }
  ],
  country: [
    {
      required: true,
      message: 'Country is required.'
    }
  ],
  zip_code: [
    {
      required: true,
      message: 'Zip code is required.'
    }
  ],
  high_school_name: [
    {
      required: true,
      message: 'High school name is required.'
    }
  ],
  relationship: [
    {
      required: true,
      message: 'Relationship is required.'
    }
  ],
  address_same_as_student: [
    {
      required: true,
      message: 'Address same as student is required.'
    }
  ],
  counselor_name: [
    {
      required: true,
      message: 'Counselor name is required.'
    }
  ],
  counselor_email: [
    {
      required: true,
      message: 'Counselor email is required.'
    },
    {
      type: 'email',
      message: 'Email is invalid.'
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value) {
          return Promise.resolve();
        }
    
        const [username, domain] = value.split('@');
        const isFirstLetterG = domain && domain.charAt(0).toLowerCase() === 'g';
        const isGmailDomain = domain && domain.toLowerCase() === 'gmail.com';
        let pattern = /^[a-zA-Z0-9._%+-]+@(?!g(?!mail\.com$)|y(?!opmail\.com$))[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
        const isFirstLetterSmall = value.charAt(0) === value.charAt(0).toLowerCase();
        
        if(!/(\.\.)+/.test(value)){
          if (isFirstLetterG && !isGmailDomain) {
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
            if (pattern.test(value)) {
              throw new Error('Invalid email format.');
            }
          }else if(!isFirstLetterSmall && pattern.test(value)){
            throw new Error('Invalid email format.');
          }
        }
       
        return Promise.resolve();
      },
    }),
  ],
  current_grade: [
    {
      required: true,
      message: 'Current grade is required.'
    }
  ],
  home_phone: [
    {
      required: true,
      message: 'Home phone is required.'
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }
  
        const numericValue = value.replace(/\D/g, ''); 
        if (numericValue.length !== 10) {
          return Promise.reject('Please enter a 10-digit home phone number.');
        }  
        return Promise.resolve();
      },
    }),
  ],
  cell_phone: [
    {
      required: true,
      message: 'Cell phone is required.'
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve(); 
        }
  
        const numericValue = value.replace(/\D/g, ''); 
        if (numericValue.length !== 10) {
          return Promise.reject('Please enter a 10-digit cell phone number.');
        }  
        return Promise.resolve();
      },
    }),
  ],
  parent_cell_phone: [
    {
      required: true,
      message: 'Cell phone is required.'
    }
  ],
  city: [
    {
      required: true,
      message: 'City is required.'
    }
  ],
  date_of_birth: [
    {
      required: true,
      message: 'Date of birth is required.'
    },
    {
      validator(_, value) {
        const currentDate = new Date();
        const selectedDate = new Date(value);
        const diffTime = Math.abs(currentDate - selectedDate);
        const age = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
  
        if(!isNaN(Date.parse(value)) ===  false && value  !== undefined){
          return Promise.reject('Please enter valid date');
        }
        else if(age > 18){
          return Promise.reject('Please enter valid DOB');
        }
        else {
          return Promise.resolve();
        } 
      },
    },
  ],
  first_name: [
    {
      required: true,
      message: 'First name is required.'
    }
  ],
  parent_email: [
    {
      required: true,
      message: 'Email is required.'
    },
    {
      type: 'email',
      message: 'Email is invalid.'
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value) {
          return Promise.resolve();
        }
    
        const [username, domain] = value.split('@');
        const isFirstLetterG = domain && domain.charAt(0).toLowerCase() === 'g';
        const isGmailDomain = domain && domain.toLowerCase() === 'gmail.com';
        let pattern = /^[a-zA-Z0-9._%+-]+@(?!g(?!mail\.com$)|y(?!opmail\.com$))[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
        const isFirstLetterSmall = value.charAt(0) === value.charAt(0).toLowerCase();
        
        if(!/(\.\.)+/.test(value)){
          if (isFirstLetterG && !isGmailDomain) {
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
            if (pattern.test(value)) {
              throw new Error('Invalid email format.');
            }
          }else if(!isFirstLetterSmall && pattern.test(value)){
            throw new Error('Invalid email format.');
          }
        }
       
        return Promise.resolve();
      },
    }),
  ],
  parent_email_2:[
    {
      type: 'email',
      message: 'Email is invalid.'
    },
    ({ getFieldValue }) => ({
      validator: async (_, value) => {
        if (!value) {
          return Promise.resolve();
        }
    
        const [username, domain] = value.split('@');
        const isFirstLetterG = domain && domain.charAt(0).toLowerCase() === 'g';
        const isGmailDomain = domain && domain.toLowerCase() === 'gmail.com';
        let pattern = /^[a-zA-Z0-9._%+-]+@(?!g(?!mail\.com$)|y(?!opmail\.com$))[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
        const isFirstLetterSmall = value.charAt(0) === value.charAt(0).toLowerCase();
       
        if(!/(\.\.)+/.test(value)){
          if (isFirstLetterG && !isGmailDomain) {
            const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
            if (pattern.test(value)) {
              throw new Error('Invalid email format.');
            }
          }else if(!isFirstLetterSmall && pattern.test(value)){
            throw new Error('Invalid email format.');
          }
        }
       
        return Promise.resolve();
      },
    }),
  ],
  last_name: [
    {
      required: true,
      message: 'Last name is required.'
    }
  ],
  how_did_hear_about: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  gender: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  confirm_age: [
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  state:[
    {
      required: true,
      message: 'Please state is required.'
    }
  ],
  highest_education:[
    {
      required: true,
      message: 'Please select an option'
    }
  ],
  work_experience:[
    {
      required: true,
      message: 'Please select an option'
    }
  ]
};

export const timeZone = [
  'EST Eastern Standard Time GMT-5:00',
  'CST Central Standard Time GMT-6:00',
  'MST Mountain Standard Time GMT-7:00',
  'PST Pacific Standard Time GMT-8:00',
  'AST Alaska Standard Time GMT-9:00',
  'HST Hawaii Standard Time GMT-10:00',
  'GMT Greenwich Mean Time GMT',
  'UTC Universal Coordinated Time GMT',
  'ECT European Central Time GMT+1:00',
  'EET Eastern European Time GMT+-2:00',
  'ART (Arabic) Egypt Standard Time GMT+2:00',
  'EAT Eastern African Time GMT+3:00',
  'MET Middle East Time GMT+3:30',
  'NET Near East Time GMT+4:00',
  'PLT Pakistan Lahore Time GMT+5:00',
  'IST India Standard Time GMT+5:30',
  'BST Bangladesh Standard Time GMT+6:00',
  'VST Vietnam Standard Time GMT+7:00',
  'CST China Standard Time GMT+8:00',
  'JST Japan Standard Time GMT+9:00',
  'ACT Australia Central Time GMT+9:30',
  'AET Australia Eastern Time GMT+10:00',
  'SST Solomon Standard Time GMT+11:00',
  'NST New Zealand Standard Time GMT+12:00',
  'MIT Midway Islands Time GMT-11:00',
  'PNT Phoenix Standard Time GMT-7:00',
  'IET Indiana Eastern Standard Time GMT-5:00',
  'PRT Puerto Rico and US Virgin lslands Time GMT-4:00',
  'CNT Canada Newfoundland Time GMT-3:30',
  'AGT Argentina Standard Time GMT-3:00',
  'BET Brazil Eastern Time GMT-3:00',
  'CAT Central African Time GMT-1:00'
];

export const applicationFormat = [
  {
    id: 1,
    key: 'course_selection',
    title: 'Course selection',
    children: [
      {
        type: 'CustomSelect',
        title: 'Certificate Course applying for',
        name: 'course_id',
        optionKey: 'allCourse',
        placeholder: '',
        courseSelection: true,
        customRender: true,
        disabled: true
      },
      {
        type: 'CourseRadio',
        title: 'Applying for',
        name: 'course_id',
        optionKey: 'course_id',
        placeholder: '',
        options: Course,
      },
      {
        type: 'CustomSelect',
        title: 'Start date',
        name: 'start_date',
        optionKey: 'courseDate',
        placeholder: 'Please Select the start date',
        customRender: true
      },
      {
        type: 'CustomRadio',
        title: 'Please select one',
        name: 'confirm_age',
        datakey: 'confirm_age',
        optionKey: 'confirm_age',
        options: Age,
      }
    ]
  },
  {
    id: 2,
    key: 'personal_information',
    title: 'personal information',
    children: [
      {
        type: 'input',
        title: 'Legal first name',
        name: 'first_name',
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Legal last name',
        name: 'last_name',
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Middle Name',
        name: 'middle_name'
      },
      {
        type: 'input',
        title: 'Preferred Name',
        name: 'preffered_name'
      },
      {
        type: 'input',
        title: 'Email',
        name: 'email',
        onBlurTrack: true
      },
      {
        type: 'CustomSelect',
        title: 'Country',
        name: 'country',
        optionKey: 'countries',
        mode: '',
        placeholder: 'Select a country',
        options: countries,
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Home Phone',
        name: 'home_phone',
        maxLength:10,
        pattern:"[0-9]*",
        placeholder:'5555555555',
      },
      {
        type: 'input',
        title: 'Cell Phone',
        name: 'cell_phone',
        placeholder:'5555555555',
        maxLength:10,
        pattern:"[0-9]*",
        onBlurTrack: true
      },
      {
        type: 'CustomCheckbox',
        title: '',
        name: 'sms',
        datakey: 'sms',
        optionKey: 'sms',
        mode: '',
        options:  {
          label: (
            <>
             By checking this box you agree to receive important information about your application and enrollment via text messages from USC Online Programs. Message and data rates may apply. Message frequency varies. If you have any questions regarding privacy, please read our{' '}
             <a style={{ color: '#6F87CA' }} target='_blank' href="https://certificates.online.usc.edu/terms-of-use">Terms of Use</a> and <a style={{ color: '#6F87CA' }} target='_blank' href="https://www.usc.edu/pages/usc-privacy-notice/">Privacy Policy</a>.
            </>
          ),
          value: (
            <>
             By checking this box you agree to receive important information about your application and enrollment via text messages from USC Online Programs. Message and data rates may apply. Message frequency varies. If you have any questions regarding privacy, please read our
               <a style={{ color: '#6F87CA' }} target='_blank' href="https://certificates.online.usc.edu/terms-of-use">Terms of Use</a> and <a style={{ color: '#6F87CA' }} target='_blank' href="https://www.usc.edu/pages/usc-privacy-notice/">Privacy Policy</a>.
            </>
          ),
        },
        onBlurTrack: true
      },
      {
        type: 'DateInput',
        title: 'Date of Birth',
        name: 'date_of_birth'
      },
      {
        type: 'input',
        title: 'Address',
        name: 'address'
      },
      {
        type: 'input',
        title: 'Address 2',
        name: 'address2'
      },
      {
        type: 'input',
        title: 'City',
        name: 'city'
      },
      // {
      //   type: 'input',
      //   title: 'State',
      //   name: 'state'
      // },
      {
        type: 'CustomSelect',
        title: 'State',
        name: 'state',
        optionKey: 'State',
        mode: '',
        placeholder: 'Select a State',
        options: states
      },
      {
        type: 'input',
        title: 'Province/County',
        name: 'province_county'
      },
      {
        type: 'input',
        title: 'Zip/Postal Code',
        name: 'zip_code'
      },
      {
        type: 'CustomSpecificCheckbox',
        title: 'How did you hear about us? Please select all that apply',
        name: 'how_did_hear_about',
        datakey: 'heardItem',
        optionKey: 'howHeared',
        mode: 'multiple',
        options: howHeared
      },
      {
        type: 'CustomSpecificRadio',
        title: 'Gender Identification',
        name: 'gender',
        datakey: 'items',
        optionKey: 'gender',
        options: gender
      },
      {
        type: 'CustomRadio',
        title: 'Demographic Information: Are you Hispanic/Latino?',
        name: 'demographic_info',
        optionKey: 'initialRadioOption',
        options: initialRadioOption
      },
      {
        type: 'CustomSpecificCheckbox',
        title:
          'Regardless of your answer to the prior question, please indicate how you identify yourself. Check one or more ',
        name: 'identity',
        mode: 'multiple',
        optionKey: 'regardless',
        placeholder: 'Please Select',
        options: regardless
      },
      {
        type: 'CustomSpecificRadio',
        title: 'Residency',
        name: 'residency',
        optionKey: 'residency',
        options: residency
      }
    ]
  },
  {
    id: 3,
    key: 'parents_information_1',
    title: 'Parent / Guardian Information ',
    subTitle: 'Parent / Guardian #1',
    children: [...parentInfo]
  },
  {
    id: 4,
    key: 'parents_information_2',
    title: '                ',
    subTitle: 'Parent / Guardian #2 Optional',
    children: [...parentInfo2]
  },
  {
    id: 5,
    key: 'highschool_information',
    title: 'high school information',
    children: [
      {
        type: 'input',
        title: 'High School Name',
        name: 'high_school_name'
      },
      {
        type: 'input',
        title: 'Address',
        name: 'address'
      },
      {
        type: 'input',
        title: 'City',
        name: 'city'
      },
      {
        type: 'input',
        title: 'State',
        name: 'state'
      },
      {
        type: 'input',
        title: 'Province/County',
        name: 'province_county'
      },
      {
        type: 'CustomSelect',
        title: 'Country',
        name: 'country',
        optionKey: 'countries',
        mode: '',
        placeholder: 'Select a country',
        options: countries
      },
      {
        type: 'input',
        title: 'Zip/Postal Code',
        name: 'zip_postal_code'
      },
      {
        type: 'input',
        title: 'Phone',
        name: 'phone'
      },
      {
        type: 'input',
        title: 'Counselor Name',
        name: 'counselor_name'
      },
      {
        type: 'input',
        title: 'Counselor Email',
        name: 'counselor_email'
      },
      {
        type: 'CustomSelect',
        title: 'Current Grade',
        name: 'current_grade',
        optionKey: 'grade',
        mode: '',
        placeholder: 'Please Select',
        options: grade
      }
    ]
  }
];


export const applicationFormat_age_old = [
  {
    id: 1,
    key: 'course_selection',
    title: 'Course selection',
    children: [
      {
        type: 'CustomSelect',
        title: 'Certificate Course applying for',
        name: 'course_id',
        optionKey: 'allCourse',
        placeholder: '',
        courseSelection: true,
        customRender: true,
        disabled: true
      },
      {
        type: 'CourseRadio',
        title: 'Applying for',
        name: 'course_id',
        optionKey: 'course_id',
        placeholder: '',
        options: Course,
      },
      {
        type: 'CustomSelect',
        title: 'Start date',
        name: 'start_date',
        optionKey: 'courseDate',
        placeholder: 'Please Select the start date',
        customRender: true
      },
      {
        type: 'CustomRadio',
        title: 'Please select one',
        name: 'confirm_age',
        datakey: 'confirm_age',
        optionKey: 'confirm_age',
        options: Age,
      }
    ]
  },
  {
    id: 2,
    key: 'personal_information',
    title: 'personal information',
    children: [
      {
        type: 'input',
        title: 'Legal first name',
        name: 'first_name',
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Legal last name',
        name: 'last_name',
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Email',
        name: 'email',
        onBlurTrack: true
      },
      {
        type: 'CustomSelect',
        title: 'Country',
        name: 'country',
        optionKey: 'countries',
        mode: '',
        placeholder: 'Select a country',
        options: countries,
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Cell Phone',
        name: 'cell_phone',
        maxLength:10,
        pattern:"[0-9]*",
        placeholder:'5555555555',
        onBlurTrack: true
      },
      {
        type: 'CustomCheckbox',
        title: '',
        name: 'sms',
        datakey: 'sms',
        optionKey: 'sms',
        mode: '',
        options:  {
          label: (
            <>
              By checking this box you agree to receive important information about your application and enrollment via text messages from USC Online Programs. Message and data rates may apply. Message frequency varies. If you have any questions regarding privacy, please read our
             <a style={{ color: '#6F87CA' }} target='_blank' href="https://certificates.online.usc.edu/terms-of-use">Terms of Use</a> and <a style={{ color: '#6F87CA' }} target='_blank' href="https://www.usc.edu/pages/usc-privacy-notice/">Privacy Policy</a>.
            </>
          ),
          value: (
            <>
              By checking this box you agree to receive important information about your application and enrollment via text messages from USC Online Programs. Message and data rates may apply. Message frequency varies. If you have any questions regarding privacy, please read our
               <a style={{ color: '#6F87CA' }} target='_blank' href="https://certificates.online.usc.edu/terms-of-use">Terms of Use</a> and <a style={{ color: '#6F87CA' }} target='_blank' href="https://www.usc.edu/pages/usc-privacy-notice/">Privacy Policy</a>.
            </>
          ),
        },
        onBlurTrack: true
      },
      {
        type: 'input',
        title: 'Address',
        name: 'address'
      },
      {
        type: 'input',
        title: 'City',
        name: 'city'
      },
      // {
      //   type: 'input',
      //   title: 'State',
      //   name: 'state'
      // },
      {
        type: 'CustomSelect',
        title: 'State',
        name: 'state',
        optionKey: 'State',
        mode: '',
        placeholder: 'Select a State',
        options: states
      },
      {
        type: 'input',
        title: 'Province/County',
        name: 'province_county'
      },
     
      {
        type: 'input',
        title: 'Zip/Postal Code',
        name: 'zip_code'
      },
      {
        type: 'CustomSpecificCheckbox',
        title: 'How did you hear about us? Please select all that apply',
        name: 'how_did_hear_about',
        datakey: 'heardItem',
        optionKey: 'howHeared',
        mode: 'multiple',
        options: howHeared_cert
      },
      {
        type: 'CustomSpecificRadio',
        title: 'Gender Identification',
        name: 'gender',
        datakey: 'items',
        optionKey: 'gender',
        options: gender_cert
      },
    
      {
        type: 'CustomSpecificCheckbox',
        title:
          ' Please indicate how you identify yourself. (Please select all that apply)',
        name: 'identity',
        mode: 'multiple',
        optionKey: 'regardless',
        placeholder: 'Please Select',
        options: regardless
      },
      {
        type: 'CustomSpecificRadio',
        title: 'Residency',
        name: 'residency',
        optionKey: 'residency',
        options: residency
      }
    ]
  },
  {
    id: 3,
    key: 'education_and_employment',
    title: 'Education and Employment ',
    children: [
      {
        type: 'CustomSpecificCheckbox',
        title:'What is the highest education level that you have completed?',
        name: 'highest_education',
        mode: 'multiple',
        optionKey: 'regardless',
        placeholder: 'Please Select',
        options: Educationlevel
      },
      {
        type: 'CustomRadio',
        title: 'Years of work experience in your field?',
        name: 'work_experience',
        optionKey: 'initialRadioOption',
        options: workexperience
      },
    ]
  }
]