import footer_logo from "../../assets/images/footer_logo.svg";
// import logo from '../../assets/images/USC-CA.png'

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 footer_content">
              <div className="footer_logo">
                <img onClick={() => window.open("https://online.usc.edu/")} src={footer_logo} alt="footer_logo"  />
              </div>
              <p className="copy_right">Copyright © 2024 University of Southern California <span>|</span>
                <a rel="noreferrer" target="_blank" href='https://policy.usc.edu/protecting-minors/'> Youth Protection Policy </a> |
                <a rel="noreferrer" target="_blank" href='https://eeotix.usc.edu/notice-of-non-discrimination/'> Non-Discrimination Policy </a> | 
                <a rel="noreferrer" target="_blank" href='https://www.usc.edu/pages/usc-privacy-notice/'> Privacy Notice </a> |
                <a rel="noreferrer" target="_blank" href='https://certificates.online.usc.edu/terms-of-use'> Terms of Use </a>
                </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
