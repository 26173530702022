import axiosClient from "../../api/axiosClient";

const SignupModel = {
    state: {},
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async verifyUser(payload) {
            const { token, setFormData, history } = payload;
            axiosClient.get(`/get_cert_email_from_token/${token}`).then(res => {
                if (res.data.status === 200) {
                    if(res.data.skillup_user === "Yes"){
                        history.push('/signin');
                    }
                    setFormData((old) => { return { ...old, email: res.data.email, first_name: res.data.first_name, last_name: res.data.last_name } });
                } else if (res.data.status === 400) {
                    history.push('/home');
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async registerUser(payload) {
            const { data, setSubmitted , setLoading} = payload;
            axiosClient.post('/register_cert_user', data).then(res => {
                setLoading(false);
                if (res.data.status === 200) {
                    setSubmitted(true);
                } else if (res.data.status === 103) {
                    dispatch.ErrorModel.handleErrorPop({
                        header: "oops! There is some error in creating your profile", body: <>
                            Please contact us at <a className="pop_email" href='mailto:certificates@usc.edu'>certificates@usc.edu</a> to help you resolve this issue.
                        </>, bodyClass: 'mb_37'
                    });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                setLoading(false);
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.response.data.message });
            });
        },
    }),
};

export default SignupModel;