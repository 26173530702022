import CustomModal from "../../hoc/CustomModal";
import { useEffect, useState } from "react";
import PopComponent from "../popContent";
import { Form } from 'antd';
import CustomInput from '../../component/CustomInput';
import FilledButton from '../../component/FilledButton';
import store from '../../redux/Store';
import { useSelector } from "react-redux";

const SignIn = ({ history, ...rest }) => {
    const [error, setError] = useState({});
    const [modalName, setModalName] = useState("ErrorPop");
    const [showPassword, setShowPassword] = useState({ password: false });
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [formData, setFormData] = useState({});
    const [form] = Form.useForm();
    const params = rest.match.params;
    const state = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    let Modal = PopComponent[modalName];
    form.setFieldsValue(formData);
    const { dispatch } = store;

    useEffect(() => {
        if (params?.token) {
            dispatch.SigninModel.verifyUserSignIn({ token: params?.token, formData, history });
        }
    }, [params]);

    const rules = {
        email: [
            {
                validator: (_, value) => {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!value) {
                        return Promise.reject("Please enter your email");
                    } else if (!re.test(String(value).toLowerCase())) {
                        return Promise.reject("Please enter a valid email");
                    } else {
                        return Promise.resolve();
                    }
                }
            }
        ],
        password: [
            {
                validator: (_, value) => {
                    const re = /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;
                    if (!value) {
                        return Promise.reject("Please enter your password");
                    } else if (!re.test(value)) {
                        return Promise.reject("Not a valid password");
                    } else {
                        return Promise.resolve();
                    }
                }
            }
        ],
        confirmPassword: [
            {
                validator: (_, value) => {
                    if (!value) {
                        return Promise.reject("Please enter confirm password");
                    } else if (formData.password !== formData.confirmPassword) {
                        return Promise.reject("Password and confirm password must be same");
                    } else {
                        return Promise.resolve();
                    }
                }
            },
        ],
    };

    const handleOpenModal = (type, data) => {
        let flag = 0;
        switch (type) {
            case 'ErrorPop': {
                setModalName(type);
                setError(data);
                setModalOpen(true);
                break;
            }
            default: {
                setModalName(type);
                setModalOpen(!modalOpen);
                setEmail('');
                setEmailError("");
                break;
            }
        }
        if (flag === 1) {
            setModalName(type);
            setModalOpen(!modalOpen);
        }
    };

    const handleForgotPassword = () => {
        let checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
        if (!email) {
            setEmailError("Please enter your registered email to continue");
            return false
        } else if (!checkEmail) {
            setEmailError("Please enter a valid email");
            return false
        }
        const data = {
            email: email
        };
        handleOpenModal();
        dispatch.SigninModel.forgotPassword(data);
    };

    const handleShow = (type) => {
        setShowPassword({ ...showPassword, [type]: !showPassword[type] })
    };

    const handleClear = (form) => {
        setFormData({});
        form.setFieldsValue({ email: "", password: "", confirmPassword: "" });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onFocus = () => {
        setEmailError('');
    }

    const onFinish = (data) => {
        data['email'] =  data?.email?.toLowerCase()
        setLoading(true);
        dispatch.SigninModel.logInUser({ data, history, handleOpenModal, setLoading });
    };

    const reSendEmail = (data) => {
        dispatch.SigninModel.reSendMail({ email: data });
        handleOpenModal();
    };

    return (
        <div className="page_wrapper">
            <div className="signin">
                <div className="container">
                    <div className="row">
                        <div className="col-12 signin signUp">
                            <h3 className="mb_60">{params?.token ? 'Thank you for confirming your account! Please sign in below.' : 'Welcome Back! Great to see you here.'}</h3>
                            <Form
                                form={form}
                                name="register"
                                onFinish={onFinish}
                                onFinishFailed={(err) => { console.log(`err`, err) }}
                                scrollToFirstError
                                initialValues={formData}
                            >
                                <h2>SIGN IN</h2>
                                <Form.Item name="email" rules={rules.email} className="form_group">
                                    <CustomInput
                                        label="Email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        inputClass='form_control'
                                    />
                                </Form.Item>
                                <div className="mail_box">
                                    <Form.Item name="password" rules={rules.password} className="form_item form_group">
                                        <CustomInput
                                            label="Password"
                                            type="password"
                                            name="password"
                                            id="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            className="password"
                                            inputClass='form_control'
                                            pass_eye='grideye'
                                            handleShow={handleShow}
                                            showPassword={showPassword}
                                        />
                                    </Form.Item>
                                    <p className="forgot" onClick={() =>
                                        handleOpenModal('ErrorPop', {
                                            header: "reset password",
                                            forget: true,
                                            body: "Please enter your email address below and we'll send you an email to reset your password."
                                        })}>Forgot Password?</p>
                                    <div className="form_group pt_1 pb_2">
                                        <button type='reset' onClick={() => handleClear(form)} className="btn btn_gray">clear</button>
                                        <FilledButton loading={loading} type='submit' value="submit" className="btn_primary ml_1" />
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {
                            Modal && (
                                <CustomModal open={modalOpen} handleOpen={handleOpenModal} resetPass="resetPass">
                                    <Modal
                                        loading={loading}
                                        error={error}
                                        email={email}
                                        emailError={emailError}
                                        setEmail={setEmail}
                                        onFocus={onFocus}
                                        handleSubmit={handleForgotPassword}
                                        handleOpenModal={handleOpenModal}
                                        subBodyFunction={reSendEmail}
                                    />
                                </CustomModal>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn
